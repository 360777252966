<template>
  <div class="enterprice-card">
    <div class="enterprice-card__header">
      <div class="form-group">
        <div class="form-label">Сальдо на начало</div>
        <input type="text" class="form-control enterprice-card__control">
      </div>
      <div class="form-group">
        <div class="form-label">Сальдо на конец</div>
        <input type="text" class="form-control enterprice-card__control">
      </div>
      <div class="enterprice-card__controls">
        <a href="" class="enterprice-card__link">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.58621 11.4958C6.58631 11.4959 6.58642 11.496 6.58653 11.4961C6.60001 11.5096 6.61421 11.5223 6.62894 11.5344C6.6357 11.54 6.64283 11.5448 6.64978 11.55C6.65807 11.5562 6.66619 11.5627 6.6748 11.5684C6.68311 11.574 6.69173 11.5788 6.70026 11.5839C6.70808 11.5886 6.71571 11.5934 6.72374 11.5977C6.73244 11.6024 6.74138 11.6063 6.75027 11.6105C6.75864 11.6145 6.76689 11.6187 6.77548 11.6222C6.78409 11.6258 6.7929 11.6287 6.80165 11.6318C6.81086 11.6351 6.81994 11.6386 6.82935 11.6415C6.8381 11.6441 6.84698 11.6461 6.85584 11.6483C6.86544 11.6507 6.87493 11.6534 6.88472 11.6553C6.89497 11.6574 6.90531 11.6586 6.91562 11.66C6.92412 11.6612 6.93251 11.6629 6.94113 11.6637C6.97941 11.6675 7.01799 11.6675 7.05627 11.6637C7.06489 11.6629 7.07328 11.6613 7.08178 11.66C7.09209 11.6586 7.10243 11.6574 7.11268 11.6553C7.12247 11.6534 7.13196 11.6507 7.14156 11.6483C7.15039 11.6461 7.1593 11.6441 7.16805 11.6415C7.17746 11.6386 7.18656 11.6351 7.19575 11.6318C7.2045 11.6286 7.21331 11.6258 7.22192 11.6222C7.23051 11.6187 7.23876 11.6145 7.24713 11.6105C7.25602 11.6063 7.26496 11.6024 7.27365 11.5977C7.28169 11.5934 7.28932 11.5885 7.29714 11.5839C7.30567 11.5788 7.31429 11.574 7.3226 11.5684C7.33121 11.5627 7.33933 11.5562 7.34762 11.55C7.35456 11.5448 7.3617 11.54 7.36846 11.5344C7.38319 11.5223 7.39739 11.5096 7.41087 11.4961C7.41098 11.496 7.41108 11.4959 7.41119 11.4958L11.4945 7.41248C11.7223 7.18468 11.7223 6.81532 11.4945 6.58752C11.2667 6.35972 10.8974 6.35972 10.6696 6.58752L7.58201 9.67504V0.583324C7.58201 0.26116 7.32085 0 6.99869 0C6.67652 0 6.41536 0.26116 6.41536 0.583324V9.67504L3.32784 6.58752C3.10004 6.35972 2.73068 6.35972 2.50288 6.58752C2.27508 6.81532 2.27508 7.18468 2.50288 7.41248L6.58621 11.4958Z" fill="white"/>
            <path d="M11.082 12.8333H2.91536C2.59319 12.8333 2.33203 13.0944 2.33203 13.4166C2.33203 13.7387 2.59319 13.9999 2.91536 13.9999H11.082C11.4042 13.9999 11.6654 13.7388 11.6654 13.4166C11.6654 13.0944 11.4042 12.8333 11.082 12.8333Z" fill="white"/>
          </svg>
        </a>
        <button  class="enterprice-card__arr">
          <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.3293 5.87341L11.8643 0.734858C12.0455 0.566499 12.0452 0.293923 11.8634 0.125846C11.6815 -0.0421 11.3869 -0.041666 11.2055 0.126714L5.99998 4.9594L0.794435 0.12654C0.613029 -0.0418184 0.318632 -0.0422525 0.136757 0.125672C0.0455856 0.209928 -1.40071e-08 0.320308 -1.8834e-08 0.430688C-2.36486e-08 0.540786 0.0452804 0.650733 0.135819 0.734836L5.67068 5.87341C5.75782 5.9545 5.87642 6 5.99998 6C6.12354 6 6.24199 5.95437 6.3293 5.87341Z" fill="#24ABE2"/>
          </svg>
        </button>
      </div>
    </div>
    <div id="enterprice-1" class="collapse">
      <div class="enterprice-card__body">
        <table class="enterprice-card__table">
          <thead><tr><th>Дата</th> <th>Дебет</th> <th>Кредит</th> <th>Сумма Дт</th> <th>Сумма Кт</th> <th>Комментарий</th></tr></thead> <tbody><tr><td>13/5/2021</td> <td>361</td> <td>702</td> <td>1 671.65</td> <td>1 671.65</td> <td>
      Компания демонстрирует современный подход в диджитализации бизнес-процессов и коммуникации, развивает программу лояльности «Фокс Клуб».
    </td></tr> <tr><td>13/5/2021</td> <td>361</td> <td>702</td> <td>1 671.65</td> <td>1 671.65</td> <td>
      Компания демонстрирует современный подход в диджитализации бизнес-процессов и коммуникации, развивает программу лояльности «Фокс Клуб».
    </td></tr> <tr><td>13/5/2021</td> <td>361</td> <td>702</td> <td>1 671.65</td> <td>1 671.65</td> <td>
      Компания демонстрирует современный подход в диджитализации бизнес-процессов и коммуникации, развивает программу лояльности «Фокс Клуб».
    </td></tr> <tr><td>13/5/2021</td> <td>361</td> <td>702</td> <td>1 671.65</td> <td>1 671.65</td> <td>
      Компания демонстрирует современный подход в диджитализации бизнес-процессов и коммуникации, развивает программу лояльности «Фокс Клуб».
    </td></tr></tbody></table></div></div>
    <!--<b-collapse accordion="enterprice-accordion" :id="'enterprice-'+id">
      <div class="enterprice-card__body">
        <table class="enterprice-card__table">
          <thead>
          <tr>
            <th>Дата</th>
            <th>Дебет</th>
            <th>Кредит</th>
            <th>Сумма Дт</th>
            <th>Сумма Кт</th>
            <th>Комментарий</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>13/5/2021</td>
            <td>361</td>
            <td>702</td>
            <td>1 671.65</td>
            <td>1 671.65</td>
            <td>
              Компания демонстрирует современный подход в диджитализации бизнес-процессов и коммуникации, развивает программу лояльности «Фокс Клуб».
            </td>
          </tr>
          <tr>
            <td>13/5/2021</td>
            <td>361</td>
            <td>702</td>
            <td>1 671.65</td>
            <td>1 671.65</td>
            <td>
              Компания демонстрирует современный подход в диджитализации бизнес-процессов и коммуникации, развивает программу лояльности «Фокс Клуб».
            </td>
          </tr>
          <tr>
            <td>13/5/2021</td>
            <td>361</td>
            <td>702</td>
            <td>1 671.65</td>
            <td>1 671.65</td>
            <td>
              Компания демонстрирует современный подход в диджитализации бизнес-процессов и коммуникации, развивает программу лояльности «Фокс Клуб».
            </td>
          </tr>
          <tr>
            <td>13/5/2021</td>
            <td>361</td>
            <td>702</td>
            <td>1 671.65</td>
            <td>1 671.65</td>
            <td>
              Компания демонстрирует современный подход в диджитализации бизнес-процессов и коммуникации, развивает программу лояльности «Фокс Клуб».
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-collapse>-->
  </div>

</template>
<script>
export default {

}
</script>
